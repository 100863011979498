import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE_URL } from "../../constants";

class DeadCardsBox extends Component {
  render() {
    if (this.props.loadedDeadCards.length > 0) {
      return (
          <div className="ui light-grey-display-box message">
          {/*<h3>Dead Cards</h3>*/}
          <div className="ui five cards">
            {this.props.loadedDeadCards.map((cellValue, idx) => (
              <div className="ui card">
                <img
                  width="60"
                  src={BASE_URL + `static/cards/${cellValue}.png`}
                />
              </div>
            ))}
          </div>
        </div>
      )
  } else {
    return (
      <div></div>
    )
  }

  }
}

const mapStateToPros = (state) => {
  return {
    loadedDeadCards: state.loadedDeadCards,
  };
};

export default connect(mapStateToPros)(DeadCardsBox);
