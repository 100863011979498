import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "../assets/css/App.css";
import Header from "./Header";
import RangesTable from "./tables/RangesTable";
import CreateRangeForm from "./forms/CreateRangeForm";
import CombosTable from "./tables/CombosTable";
import FloppedCardsMatrix from "./matrices/FloppedCardsMatrix";
import DeadCardsMatrix from "./matrices/DeadCardsMatrix";
import RangeMatrix from "./matrices/RangeMatrix";
import NotificationBox from "./boxes/NotificationBox";
import FloppedCardsBox from "./boxes/FloppedCardsBox";
import DeadCardsBox from "./boxes/DeadCardsBox";
import EquitiesTable from "./tables/EquitiesTable";
import PotOddsTable from "./tables/PotOddsTable";
import DrawHandExpectedValueTable from "./tables/DrawHandExpectedValueTable";
import MadeHandExpectedValueTable from "./tables/MadeHandExpectedValueTable";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Route path="/" exact>
          <div className="ui grid center aligned">
            <div className="row">
              <div className="three wide column">
                <NotificationBox />
                <CreateRangeForm />
                <RangesTable />
              </div>
              <div className="four wide column">
                <RangeMatrix />
              </div>
              <div className="two wide column">
                <FloppedCardsMatrix />
              </div>
              <div className="two wide column">
                <DeadCardsMatrix />
              </div>
              <div className="four wide column">
                <FloppedCardsBox />
                <DeadCardsBox />
                <EquitiesTable />
              </div>
            </div>
            <div className="row">
              <div className="two wide column">
                <PotOddsTable />
              </div>
              <div className="four wide column">
                <MadeHandExpectedValueTable />
              </div>
              <div className="five wide column">
                <DrawHandExpectedValueTable />
              </div>
              <div className="four wide column">
                <CombosTable />
              </div>
            </div>
          </div>
        </Route>
      </BrowserRouter>
    </div>
  );
};

export default App;
