import React, { Component } from "react";
import { connect } from "react-redux";
import MatrixCell from "./MatrixCell";
import {
  addCellToLoadedFloppedCards,
  deleteCellFromLoadedFloppedCards,
  resetLoadedFloppedCards,
  updateNotification,
} from "../../actions";
import { defineCellCssClass } from "../../utils";
import { MAX_NUM_FLOPPED_CARDS } from "../../constants";

let cells = [
  ["Ah", "Ac", "Ad", "As"],
  ["Kh", "Kc", "Kd", "Ks"],
  ["Qh", "Qc", "Qd", "Qs"],
  ["Jh", "Jc", "Jd", "Js"],
  ["Th", "Tc", "Td", "Ts"],
  ["9h", "9c", "9d", "9s"],
  ["8h", "8c", "8d", "8s"],
  ["7h", "7c", "7d", "7s"],
  ["6h", "6c", "6d", "6s"],
  ["5h", "5c", "5d", "5s"],
  ["4h", "4c", "4d", "4s"],
  ["3h", "3c", "3d", "3s"],
  ["2h", "2c", "2d", "2s"],
];

class FloppedCardsMatrix extends Component {
  addCellHandler = (cellValue) => {
    if (this.props.loadedFloppedCards.length >= MAX_NUM_FLOPPED_CARDS) {
      this.props.updateNotification(
        "error",
        "Added",
        `("${cellValue}") couldn't be added. Only up to 5 cards can be in the Board.`
      );
    } else if (this.props.loadedDeadCards.indexOf(cellValue) > -1) {
      this.props.updateNotification(
        "error",
        "Not Added",
        `("${cellValue}") is already in the Dead Cards group.`
      );
    } else {
      this.props.addCellToLoadedFloppedCards(cellValue);
      this.props.updateNotification(
        "info",
        "Added",
        `("${cellValue}") was added to the Board.`
      );
    }
  };

  deleteCellHandler = (cellValue) => {
    this.props.deleteCellFromLoadedFloppedCards(cellValue);
    this.props.updateNotification(
      "info",
      "Deleted",
      `("${cellValue}") was deleted from the Board.`
    );
  };

  generateCells = (row, rowIdx) => {
    return row.map((cellValue, cellIdx) => {
      return (
        <MatrixCell
          key={`${rowIdx},${cellIdx}`}
          cellValue={cellValue}
          addCellHandler={this.addCellHandler}
          deleteCellHandler={this.deleteCellHandler}
          selectedCells={this.props.loadedFloppedCards}
          cssClassWhenSelected={defineCellCssClass(cellValue, true)}
          cssClassWhenUnselected={defineCellCssClass(cellValue, false)}
        />
      );
    });
  };

  render() {
    return (
      <div>
        <h1>Flopped Cards</h1>
        {cells.map((row, rowIdx) => {
          return <div key={rowIdx}>{this.generateCells(row, rowIdx)}</div>;
        })}
        <br />
        <button
          className="ui grey button"
          onClick={() => this.props.resetLoadedFloppedCards()}
        >
          Reset
        </button>
      </div>
    );
  }
}

const mapStateToPros = (state) => {
  return {
    loadedFloppedCards: state.loadedFloppedCards,
    loadedDeadCards: state.loadedDeadCards,
  };
};

export default connect(mapStateToPros, {
  addCellToLoadedFloppedCards: addCellToLoadedFloppedCards,
  deleteCellFromLoadedFloppedCards: deleteCellFromLoadedFloppedCards,
  resetLoadedFloppedCards: resetLoadedFloppedCards,
  updateNotification: updateNotification,
})(FloppedCardsMatrix);
