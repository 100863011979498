import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchEquities } from "../../actions";

class PotOddsTable extends Component {
  calculatePotOdds = (callSize, potSize) => {
    return callSize / (potSize + callSize);
  };

  render() {
    return (
      <table className="ui celled inverted structured compact table">
        <thead>
          <tr>
            <th className="table-header" rowSpan={2}>
              Call Size
            </th>
            <th className="table-header" colSpan={3}>
              Pot Odds
              <span class="ui icon tooltip" data-tooltip="Pot Odds is the ratio between the size of the Pot and the Bet facing you." data-position="top left" data-variation="basic"><i class="question circle outline icon"></i></span>
            </th>
          </tr>
          <tr>
            <th className="table-header">5 BB</th>
            <th className="table-header">10 BB</th>
            <th className="table-header">20 BB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="table-description">1 BB</td>
            <td className="table-value">
              {(this.calculatePotOdds(1, 1) * 100).toFixed(0)} %
            </td>
            <td className="table-value">
              {(this.calculatePotOdds(1, 10) * 100).toFixed(0)} %
            </td>
            <td className="table-value">
              {(this.calculatePotOdds(1, 20) * 100).toFixed(0)} %
            </td>
          </tr>
          <tr>
            <td className="table-description">3 BB</td>
            <td className="table-value">
              {(this.calculatePotOdds(3, 1) * 100).toFixed(0)} %
            </td>
            <td className="table-value">
              {(this.calculatePotOdds(3, 10) * 100).toFixed(0)} %
            </td>
            <td className="table-value">
              {(this.calculatePotOdds(3, 20) * 100).toFixed(0)} %
            </td>
          </tr>
          <tr>
            <td className="table-description">5 BB</td>
            <td className="table-value">
              {(this.calculatePotOdds(5, 1) * 100).toFixed(0)} %
            </td>
            <td className="table-value">
              {(this.calculatePotOdds(5, 10) * 100).toFixed(0)} %
            </td>
            <td className="table-value">
              {(this.calculatePotOdds(5, 20) * 100).toFixed(0)} %
            </td>
          </tr>
          <tr>
            <td className="table-description">10 BB</td>
            <td className="table-value">
              {(this.calculatePotOdds(10, 1) * 100).toFixed(0)} %
            </td>
            <td className="table-value">
              {(this.calculatePotOdds(10, 10) * 100).toFixed(0)} %
            </td>
            <td className="table-value">
              {(this.calculatePotOdds(10, 20) * 100).toFixed(0)} %
            </td>
          </tr>
          <tr>
            <td className="table-description">20 BB</td>
            <td className="table-value">
              {(this.calculatePotOdds(20, 1) * 100).toFixed(0)} %
            </td>
            <td className="table-value">
              {(this.calculatePotOdds(20, 10) * 100).toFixed(0)} %
            </td>
            <td className="table-value">
              {(this.calculatePotOdds(20, 20) * 100).toFixed(0)} %
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadedRange: state.loadedRange,
    loadedFloppedCards: state.loadedFloppedCards,
    loadedDeadCards: state.loadedDeadCards,
    loadedEquities: state.loadedEquities,
  };
};

export default connect(mapStateToProps, {
  fetchEquities: fetchEquities,
})(PotOddsTable);
