import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchEquities } from "../../actions";
import { isEmpty } from "lodash";

class DrawHandExpectedValueTable extends Component {
  calculatePotOdds = (callSize, potSize) => {
    return callSize / (potSize + callSize);
  };
  calculateDrawHandEv = (betEquity, potOdds) => {
    let res = "";
    let outcome = "neutral";

    if (!betEquity) {
      return [outcome, res];
    }
    const diff = (betEquity - potOdds) * 100;

    if (diff > 10) {
      outcome = "positive";
      res += "+";
    } else if (diff > 0) {
      outcome = "warning";
      res += "+";
    } else {
      outcome = "negative";
      res += "-";
    }
    res += `EV (${diff.toFixed(0)} %)`;

    return [outcome, res];
  };

  getCssClassesBasedOnCalculatedEv = (betEquity, potOdds) => {
    let outcome;
    let res;

    [outcome, res] = this.calculateDrawHandEv(betEquity, potOdds);

    if (outcome === "positive") {
      return "table-value positive-cell";
    } else if (outcome === "warning") {
      return "table-value warning-cell";
    } else if (outcome === "negative") {
      return "table-value negative-cell";
    } else {
      return "table-value";
    }
  };

  render() {
    let equityNormalBet = 0.0;
    let equityAllInBet = 0.0;

    if (!isEmpty(this.props.loadedEquities)) {
      equityNormalBet = this.props.loadedEquities["draw_hand"][
        "pct_hand_equity_normal_bet"
      ];
      equityAllInBet = this.props.loadedEquities["draw_hand"][
        "pct_hand_equity_all_in_bet"
      ];
    }
    return (
      <table className="ui celled inverted structured compact table">
        <thead>
          <tr>
            <th className="table-header" rowSpan={3}>
              Call Size
            </th>
            <th className="table-header" colSpan={6}>
              Draw-Hand "EV"
              <span class="ui icon tooltip" data-tooltip="Expected value (EV) is the average result of a given play if it were made hundreds of times." data-position="top center"><i class="question circle outline icon"></i></span>
            </th>
          </tr>
          <tr>
            <th className="table-header" colSpan={2}>
              5 BB (Pot Size)
            </th>
            <th className="table-header" colSpan={2}>
              10 BB (Pot Size)
            </th>
            <th className="table-header" colSpan={2}>
              20 BB (Pot Size)
            </th>
          </tr>
          <tr>
            <th className="table-header">Normal</th>
            <th className="table-header">All-in</th>
            <th className="table-header">Normal</th>
            <th className="table-header">All-in</th>
            <th className="table-header">Normal</th>
            <th className="table-header">All-in</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="table-description">1 BB</td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityNormalBet,
                this.calculatePotOdds(1, 5)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityNormalBet,
                  this.calculatePotOdds(1, 5)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityAllInBet,
                this.calculatePotOdds(1, 5)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityAllInBet,
                  this.calculatePotOdds(1, 5)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityNormalBet,
                this.calculatePotOdds(1, 10)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityNormalBet,
                  this.calculatePotOdds(1, 10)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityAllInBet,
                this.calculatePotOdds(1, 10)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityAllInBet,
                  this.calculatePotOdds(1, 10)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityNormalBet,
                this.calculatePotOdds(1, 20)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityNormalBet,
                  this.calculatePotOdds(1, 20)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityAllInBet,
                this.calculatePotOdds(1, 20)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityAllInBet,
                  this.calculatePotOdds(1, 20)
                )[1]
              }
            </td>
          </tr>
          <tr>
            <td className="table-description">3 BB</td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityNormalBet,
                this.calculatePotOdds(3, 5)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityNormalBet,
                  this.calculatePotOdds(3, 5)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityAllInBet,
                this.calculatePotOdds(3, 5)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityAllInBet,
                  this.calculatePotOdds(3, 5)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityNormalBet,
                this.calculatePotOdds(3, 10)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityNormalBet,
                  this.calculatePotOdds(3, 10)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityAllInBet,
                this.calculatePotOdds(3, 10)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityAllInBet,
                  this.calculatePotOdds(3, 10)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityNormalBet,
                this.calculatePotOdds(3, 20)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityNormalBet,
                  this.calculatePotOdds(3, 20)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityAllInBet,
                this.calculatePotOdds(3, 20)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityAllInBet,
                  this.calculatePotOdds(3, 20)
                )[1]
              }
            </td>
          </tr>
          <tr>
            <td className="table-description">5 BB</td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityNormalBet,
                this.calculatePotOdds(5, 5)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityNormalBet,
                  this.calculatePotOdds(5, 5)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityAllInBet,
                this.calculatePotOdds(5, 5)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityAllInBet,
                  this.calculatePotOdds(5, 5)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityNormalBet,
                this.calculatePotOdds(5, 10)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityNormalBet,
                  this.calculatePotOdds(5, 10)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityAllInBet,
                this.calculatePotOdds(5, 10)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityAllInBet,
                  this.calculatePotOdds(5, 10)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityNormalBet,
                this.calculatePotOdds(5, 20)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityNormalBet,
                  this.calculatePotOdds(5, 20)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityAllInBet,
                this.calculatePotOdds(5, 20)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityAllInBet,
                  this.calculatePotOdds(5, 20)
                )[1]
              }
            </td>
          </tr>
          <tr>
            <td className="table-description">10 BB</td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityNormalBet,
                this.calculatePotOdds(10, 5)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityNormalBet,
                  this.calculatePotOdds(10, 5)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityAllInBet,
                this.calculatePotOdds(10, 5)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityAllInBet,
                  this.calculatePotOdds(10, 5)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityNormalBet,
                this.calculatePotOdds(10, 10)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityNormalBet,
                  this.calculatePotOdds(10, 10)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityAllInBet,
                this.calculatePotOdds(10, 10)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityAllInBet,
                  this.calculatePotOdds(10, 10)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityNormalBet,
                this.calculatePotOdds(10, 20)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityNormalBet,
                  this.calculatePotOdds(10, 20)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityAllInBet,
                this.calculatePotOdds(10, 20)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityAllInBet,
                  this.calculatePotOdds(10, 20)
                )[1]
              }
            </td>
          </tr>
          <tr>
            <td className="table-description">20 BB</td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityNormalBet,
                this.calculatePotOdds(20, 5)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityNormalBet,
                  this.calculatePotOdds(20, 5)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityAllInBet,
                this.calculatePotOdds(20, 5)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityAllInBet,
                  this.calculatePotOdds(20, 5)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityNormalBet,
                this.calculatePotOdds(20, 10)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityNormalBet,
                  this.calculatePotOdds(20, 10)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityAllInBet,
                this.calculatePotOdds(20, 10)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityAllInBet,
                  this.calculatePotOdds(20, 10)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityNormalBet,
                this.calculatePotOdds(20, 20)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityNormalBet,
                  this.calculatePotOdds(20, 20)
                )[1]
              }
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                equityAllInBet,
                this.calculatePotOdds(20, 20)
              )}
            >
              {
                this.calculateDrawHandEv(
                  equityAllInBet,
                  this.calculatePotOdds(20, 20)
                )[1]
              }
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadedRange: state.loadedRange,
    loadedFloppedCards: state.loadedFloppedCards,
    loadedDeadCards: state.loadedDeadCards,
    loadedEquities: state.loadedEquities,
  };
};

export default connect(mapStateToProps, {
  fetchEquities: fetchEquities,
})(DrawHandExpectedValueTable);
