import React, { Component } from "react";
import { connect } from "react-redux";
import {
  createRange,
  updateRange,
  updateNameFromLoadedRange,
  updateNotification,
} from "../../actions";

class CreateRangeForm extends Component {
  createRange = (e) => {
    e.preventDefault();
    if (!this.props.loadedRange.name) {
      this.props.updateNotification(
        "error",
        "Complete the fields",
        "Please type a Name."
      );
    } else {
      this.props.createRange(this.props.loadedRange);
      this.props.updateNotification(
        "success",
        "Created",
        "Range successfully created."
      );
    }
  };

  updateRange = (e) => {
    e.preventDefault();
    this.props.updateRange(this.props.loadedRange);
    this.props.updateNotification(
      "success",
      "Updated",
      "Range successfully updated."
    );
  };

  onChangeHandler = (e) => {
    this.props.updateNameFromLoadedRange(e.target.value);
  };

  getNamesFromRanges = (ranges) => {
    let names = [];
    ranges.map((range) => {
      return names.push(range.name);
    });
    return names;
  };

  isThereRangeWithSameName = () => {
    return (
      this.getNamesFromRanges(this.props.ranges).indexOf(
        this.props.loadedRange.name
      ) > -1
    );
  };

  render() {
    return (
      <div>
        <form className="ui form">
          <div className="two fields">
            <div className="twelve wide field">
              <input
                type="text"
                name="name"
                placeholder="Range Name"
                onChange={this.onChangeHandler}
                value={this.props.loadedRange.name}
              />
            </div>
            <div className="four wide field">
              {this.isThereRangeWithSameName() ? (
                <button className="ui orange button" onClick={this.updateRange}>
                  Update
                </button>
              ) : (
                <button className="ui button" onClick={this.createRange}>
                  Create
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadedRange: state.loadedRange,
    ranges: state.ranges,
  };
};

export default connect(mapStateToProps, {
  createRange: createRange,
  updateRange: updateRange,
  updateNameFromLoadedRange: updateNameFromLoadedRange,
  updateNotification: updateNotification,
})(CreateRangeForm);
