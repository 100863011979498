const reducers = (state = {}, action) => {
  switch (action.type) {
    case "FETCH_EQUITIES":
      return action.payload.data;
    default:
      return state;
  }
};

export default reducers;
