import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE_URL } from "../../constants";

class FloppedCardsBox extends Component {
  getTurnName = (idx) => {
    switch (idx) {
      case 3:
        return "Turn";
        break;
      case 4:
        return "River";
        break;
      default:
        return "Flop";
    }
  };
  render() {
    if (this.props.loadedFloppedCards.length > 0) {
      return (
        <div className="ui light-grey-display-box message">
          <div className="ui five cards">
            {this.props.loadedFloppedCards.map((cellValue, idx) => (
              <div className="ui card">
                <img
                  width="60"
                  src={BASE_URL + `static/cards/${cellValue}.png`}
                />
                <div>{this.getTurnName(idx)}</div>
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return(
        <div></div>
      )
    }
  }
}

const mapStateToPros = (state) => {
  return {
    loadedFloppedCards: state.loadedFloppedCards,
  };
};

export default connect(mapStateToPros)(FloppedCardsBox);
