import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchRanges,
  deleteRange,
  loadRange,
  updateNotification,
} from "../../actions";

class RangesTable extends Component {
  componentDidMount() {
    this.props.fetchRanges();
  }

  deleteRangeFromTable = (range) => {
    this.props.deleteRange(range.id);
    this.props.updateNotification(
      "error",
      "Deleted",
      "Range successfully deleted."
    );
  };

  loadRangeFromTable = (range) => {
    this.props.loadRange(range);
    this.props.updateNotification(
      "info",
      "Loaded",
      "Range successfully loaded."
    );
  };

  render() {
    return (
      this.props.ranges.length !== 0 && (
        <div>
          <table className="ui celled inverted compact table">
            <thead>
              <tr>
                <th className="one wide">#Id</th>
                <th>Range Name</th>
                <th className="one wide"> </th>
                <th className="one wide"> </th>
              </tr>
            </thead>
            <tbody>
              {this.props.ranges.map((range) => {
                return (
                  <tr key={range.id}>
                    <td data-label="Id">{range.id}</td>
                    <td data-label="Name">{range.name}</td>
                    <td data-label="Load-Button">
                      <i
                        className="folder open icon folder-icon"
                        onClick={() => this.loadRangeFromTable(range)}
                      ></i>
                    </td>
                    <td data-label="Delete-Button">
                      <i
                        className="trash icon trash-icon"
                        onClick={() => this.deleteRangeFromTable(range)}
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ranges: state.ranges,
  };
};

export default connect(mapStateToProps, {
  fetchRanges: fetchRanges,
  deleteRange: deleteRange,
  loadRange: loadRange,
  updateNotification: updateNotification,
})(RangesTable);
