import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchCombos } from "../../actions";
import {
  FOUR_OF_A_KIND,
  FULL_HOUSE,
  HIGH_CARD,
  ROYAL_FLUSH,
  FLUSH,
  STRAIGHT,
  STRAIGHT_FLUSH,
  THREE_OF_A_KIND,
  TWO_PAIR,
  ONE_PAIR,
} from "../../constants";
import { isEmpty } from "lodash";

class CombosTable extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.loadedRange.range !== this.props.loadedRange.range ||
      prevProps.loadedFloppedCards !== this.props.loadedFloppedCards ||
      prevProps.loadedDeadCards !== this.props.loadedDeadCards
    ) {
      if (
        this.props.loadedFloppedCards.length >= 3 &&
        this.props.loadedRange.range.length >= 1
      ) {
        this.props.fetchCombos(
          this.props.loadedRange.range,
          this.props.loadedFloppedCards,
          this.props.loadedDeadCards
        );
      } else {
        this.props.fetchCombos([], [], []);
      }
    }
  }

  getCssClassesBasedOnFigureStrengthAndCurrentEquity(
    figureCode,
    currentEquityFigureCode,
    numCombos
  ) {
    let outcome;

    if (!numCombos || !currentEquityFigureCode) {
      return;
    }

    if (figureCode > currentEquityFigureCode) {
      outcome = "negative";
    } else if (figureCode === currentEquityFigureCode) {
      outcome = "warning";
    } else if (figureCode < currentEquityFigureCode) {
      outcome = "positive";
    }

    if (outcome === "positive") {
      return "table-value positive-cell";
    } else if (outcome === "warning") {
      return "table-value warning-cell";
    } else if (outcome === "negative") {
      return "table-value negative-cell";
    } else {
      return "table-value";
    }
  }

  displayNumCombos(numCombos, numTotalCombos) {
    if (!numCombos || !numTotalCombos) {
      return "";
    }
    const percentage = (numCombos / numTotalCombos) * 100;
    let displayedText = `${numCombos}`;

    if (percentage) {
      displayedText += ` (${percentage.toFixed(0)} %)`;
    }
    return displayedText;
  }

  render() {
    let currentEquityFigureCode = 0;

    const numTotalCombos =
      this.props.loadedCombos["royal flush"] +
      this.props.loadedCombos["straight flush"] +
      this.props.loadedCombos["four of a kind"] +
      this.props.loadedCombos["full house"] +
      this.props.loadedCombos["flush"] +
      this.props.loadedCombos["straight"] +
      this.props.loadedCombos["three of a kind"] +
      this.props.loadedCombos["two pair"] +
      this.props.loadedCombos["one pair"] +
      this.props.loadedCombos["high card"];

    if (!isEmpty(this.props.loadedEquities)) {
      currentEquityFigureCode = this.props.loadedEquities["made_hand"][
        "highest_figure_code"
      ];
    }
    return (
      <table className="ui celled inverted compact table">
        <thead>
          <tr>
            <th className="table-header" colSpan={4}>Starting-Hand range + Flopped Cards (Board)</th>
          </tr>
          <tr>
            <th className="table-header">Figure</th>
            <th className="table-header">Combos<span class="ui icon tooltip" data-tooltip="Number of possible combinations to make a certain Poker Figure with the known cards." data-position="top right"><i class="question circle outline icon"></i></span></th>
            <th className="table-header">Figure</th>
            <th className="table-header">Combos<span class="ui icon tooltip" data-tooltip="Number of possible combinations to make a certain Poker Figure with the known cards." data-position="top right"><i class="question circle outline icon"></i></span></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="table-description">Royal Flush</td>
            <td
              className={this.getCssClassesBasedOnFigureStrengthAndCurrentEquity(
                ROYAL_FLUSH,
                currentEquityFigureCode,
                this.props.loadedCombos["royal flush"]
              )}
            >
              {this.displayNumCombos(
                this.props.loadedCombos["royal flush"],
                numTotalCombos
              )}
            </td>
            <td className="table-description">Straight Flush</td>
            <td
              className={this.getCssClassesBasedOnFigureStrengthAndCurrentEquity(
                STRAIGHT_FLUSH,
                currentEquityFigureCode,
                this.props.loadedCombos["straight flush"]
              )}
            >
              {this.displayNumCombos(
                this.props.loadedCombos["straight flush"],
                numTotalCombos
              )}
            </td>
          </tr>
          <tr>
            <td className="table-description">Four of a Kind</td>
            <td
              className={this.getCssClassesBasedOnFigureStrengthAndCurrentEquity(
                FOUR_OF_A_KIND,
                currentEquityFigureCode,
                this.props.loadedCombos["four of a kind"]
              )}
            >
              {this.displayNumCombos(
                this.props.loadedCombos["four of a kind"],
                numTotalCombos
              )}
            </td>
            <td className="table-description">Full House</td>
            <td
              className={this.getCssClassesBasedOnFigureStrengthAndCurrentEquity(
                FULL_HOUSE,
                currentEquityFigureCode,
                this.props.loadedCombos["full house"]
              )}
            >
              {this.displayNumCombos(
                this.props.loadedCombos["full house"],
                numTotalCombos
              )}
            </td>
          </tr>
          <tr>
            <td className="table-description">Flush</td>
            <td
              className={this.getCssClassesBasedOnFigureStrengthAndCurrentEquity(
                FLUSH,
                currentEquityFigureCode,
                this.props.loadedCombos["flush"]
              )}
            >
              {this.displayNumCombos(
                this.props.loadedCombos["flush"],
                numTotalCombos
              )}
            </td>
            <td className="table-description">Straight</td>
            <td
              className={this.getCssClassesBasedOnFigureStrengthAndCurrentEquity(
                STRAIGHT,
                currentEquityFigureCode,
                this.props.loadedCombos["straight"]
              )}
            >
              {this.displayNumCombos(
                this.props.loadedCombos["straight"],
                numTotalCombos
              )}
            </td>
          </tr>
          <tr>
            <td className="table-description">Three of a Kind</td>
            <td
              className={this.getCssClassesBasedOnFigureStrengthAndCurrentEquity(
                THREE_OF_A_KIND,
                currentEquityFigureCode,
                this.props.loadedCombos["three of a kind"]
              )}
            >
              {this.displayNumCombos(
                this.props.loadedCombos["three of a kind"],
                numTotalCombos
              )}
            </td>
            <td className="table-description">Two Pairs</td>
            <td
              className={this.getCssClassesBasedOnFigureStrengthAndCurrentEquity(
                TWO_PAIR,
                currentEquityFigureCode,
                this.props.loadedCombos["two pair"]
              )}
            >
              {this.displayNumCombos(
                this.props.loadedCombos["two pair"],
                numTotalCombos
              )}
            </td>
          </tr>
          <tr>
            <td className="table-description">Pair</td>
            <td
              className={this.getCssClassesBasedOnFigureStrengthAndCurrentEquity(
                ONE_PAIR,
                currentEquityFigureCode,
                this.props.loadedCombos["one pair"]
              )}
            >
              {this.displayNumCombos(
                this.props.loadedCombos["one pair"],
                numTotalCombos
              )}
            </td>
            <td className="table-description">High Card</td>
            <td
              className={this.getCssClassesBasedOnFigureStrengthAndCurrentEquity(
                HIGH_CARD,
                currentEquityFigureCode,
                this.props.loadedCombos["high card"]
              )}
            >
              {this.displayNumCombos(
                this.props.loadedCombos["high card"],
                numTotalCombos
              )}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadedRange: state.loadedRange,
    loadedFloppedCards: state.loadedFloppedCards,
    loadedDeadCards: state.loadedDeadCards,
    loadedCombos: state.loadedCombos,
    loadedEquities: state.loadedEquities,
  };
};

export default connect(mapStateToProps, {
  fetchCombos: fetchCombos,
})(CombosTable);
