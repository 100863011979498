import React from "react";
import { indexOf } from "lodash";

class MatrixCell extends React.Component {
  isCellSelected = (cellValue) => {
    if (this.props.selectedCells.length === 0) {
      return false;
    }
    return indexOf(this.props.selectedCells, cellValue) > -1;
  };

  clickHandler = (cellValue) => {
    if (this.isCellSelected(cellValue)) {
      this.props.deleteCellHandler(cellValue);
    } else {
      this.props.addCellHandler(cellValue);
    }
  };

  render() {
    const cssClasses = this.isCellSelected(this.props.cellValue)
      ? "Cell " + this.props.cssClassWhenSelected
      : "Cell " + this.props.cssClassWhenUnselected;
    return (
      <div
        className={cssClasses}
        onClick={() => {
          this.clickHandler(this.props.cellValue);
        }}
      >
        {this.props.cellValue}
      </div>
    );
  }
}

export default MatrixCell;
