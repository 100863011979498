import React, { Component } from "react";
import { connect } from "react-redux";

class NotificationBox extends Component {
  render() {
    let msgType;
    switch (this.props.notification.msgType) {
      case "error":
        msgType = "ui error message";
        break;
      case "success":
        msgType = "ui success message";
        break;
      case "warning":
        msgType = "ui warning message";
        break;
      default:
        msgType = "ui info message";
    }

    if (this.props.notification.msgType !== undefined) {
      return (
        <div className={msgType}>
          <div className="header">{this.props.notification.msgTitle}</div>
          <ul className="list">
            <li>{this.props.notification.msgContent}</li>
          </ul>
        </div>
      );
    } else {
      return (
        <div className="ui message">
          <div className="header">Welcome to PokerInsights!</div>
          <ul className="list">
            <li>PokerInsights is a Poker statistics tool</li>
            <li>Please select a Starting-Hand Range, Flopped Cards (e.g., cards in table) and Dead Cards (e.g., cards in hand)</li>
            <li>To get insights, you need to select at least 1 Starting-Hand cell, 3 Flopped Cards and 2 Dead Cards</li>
            <li><b>Beware:</b> This tool was made for fun. I strongly advice against using it in real Poker games. Use it at your own risk.</li>
          </ul>
        </div>
      );
    }
  }
}

const mapStateToPros = (state) => {
  return {
    notification: state.notification,
  };
};

export default connect(mapStateToPros)(NotificationBox);
