import React from "react";

const Header = () => {
  return (
    <div className="ui inverted menu">
      <div className="header item">
        <a href="/">PokerInsights</a>
      </div>
      <a href="/" className="item">
        Hand Ranges
      </a>
    </div>
  );
};

export default Header;
