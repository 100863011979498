const reducers = (state = [], action) => {
  switch (action.type) {
    case "ADD_CELL_TO_LOADED_DEAD_CARDS":
      return [...state, action.payload];
    case "DELETE_CELL_FROM_LOADED_DEAD_CARDS":
      return state.filter((el) => el !== action.payload);
    case "RESET_LOADED_DEAD_CARDS":
      return action.payload;
    default:
      return state;
  }
};

export default reducers;
