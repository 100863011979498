import { combineReducers } from "redux";
import rangesReducer from "./rangesReducer";
import loadedRangeReducer from "./loadedRangeReducer";
import loadedFloppedCardsReducer from "./loadedFloppedCardsReducer";
import loadedDeadCardsReducer from "./loadedDeadCardsReducer";
import loadedCombosReducer from "./loadedCombosReducer";
import notificationReducer from "./notificationReducer";
import loadedEquitiesReducer from "./loadedEquitiesReducer";

export default combineReducers({
  ranges: rangesReducer,
  loadedRange: loadedRangeReducer,
  loadedFloppedCards: loadedFloppedCardsReducer,
  loadedDeadCards: loadedDeadCardsReducer,
  loadedCombos: loadedCombosReducer,
  loadedEquities: loadedEquitiesReducer,
  notification: notificationReducer,
});
