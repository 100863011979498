const reducers = (state = [], action) => {
  switch (action.type) {
    case "FETCH_RANGES":
      return action.payload.data;
    case "DELETE_RANGE":
      return state.filter((range) => range.id !== action.payload);
    case "CREATE_RANGE":
      return [...state, action.payload.data];
    case "UPDATE_RANGE":
      return state.map((el) =>
        el.id === action.payload.data.id ? action.payload.data : el
      );
    default:
      return state;
  }
};

export default reducers;
