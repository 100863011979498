import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchEquities } from "../../actions";
import { isEmpty } from "lodash";

class MadeHandExpectedValueTable extends Component {
  calculateMadeHandEv = (handEquity, callSize, potSize) => {
    if (!handEquity) {
      return ["neutral", ""];
    }
    const rangeEquity = 1 - handEquity;

    const win = potSize * handEquity;
    const lose = (potSize + callSize) * rangeEquity;

    const diff = win - lose;
    if (diff > callSize) {
      return ["positive", `+EV (+${diff.toFixed(1)} BB)`];
    } else if (diff > 0) {
      return ["warning", `+EV (+${diff.toFixed(1)} BB)`];
    } else {
      return ["negative", `-EV (${diff.toFixed(1)} BB)`];
    }
  };

  getCssClassesBasedOnCalculatedEv = (handEquity, callSize, potSize) => {
    let outcome;
    let res;

    [outcome, res] = this.calculateMadeHandEv(handEquity, callSize, potSize);

    if (outcome === "positive") {
      return "table-value positive-cell";
    } else if (outcome === "warning") {
      return "table-value warning-cell";
    } else if (outcome === "negative") {
      return "table-value negative-cell";
    } else {
      return "table-value";
    }
  };

  render() {
    let handWins = 0.0;

    if (!isEmpty(this.props.loadedEquities)) {
      handWins = this.props.loadedEquities["made_hand"][
        "pct_hand_wins_against_range"
      ];
    }
    return (
      <table className="ui celled inverted structured compact table">
        <thead>
          <tr>
            <th className="table-header" rowSpan={3}>
              Bet Size
            </th>
            <th className="table-header" colSpan={3}>
              Made-Hand "EV"
              <span class="ui icon tooltip" data-tooltip="Expected value (EV) is the average result of a given play if it were made hundreds of times." data-position="top center"><i class="question circle outline icon"></i></span>
            </th>
          </tr>
          <tr>
            <th className="table-header">5 BB (Pot Size)</th>
            <th className="table-header">10 BB (Pot Size)</th>
            <th className="table-header">20 BB (Pot Size)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="table-description">1 BB</td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(handWins, 1, 5)}
            >
              {this.calculateMadeHandEv(handWins, 1, 5)[1]}
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(handWins, 1, 10)}
            >
              {this.calculateMadeHandEv(handWins, 1, 10)[1]}
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(handWins, 1, 20)}
            >
              {this.calculateMadeHandEv(handWins, 1, 20)[1]}
            </td>
          </tr>
          <tr>
            <td className="table-description">3 BB</td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(handWins, 3, 5)}
            >
              {this.calculateMadeHandEv(handWins, 3, 5)[1]}
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(handWins, 3, 10)}
            >
              {this.calculateMadeHandEv(handWins, 3, 10)[1]}
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(handWins, 3, 20)}
            >
              {this.calculateMadeHandEv(handWins, 3, 20)[1]}
            </td>
          </tr>
          <tr>
            <td className="table-description">5 BB</td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(handWins, 5, 5)}
            >
              {this.calculateMadeHandEv(handWins, 5, 5)[1]}
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(handWins, 5, 10)}
            >
              {this.calculateMadeHandEv(handWins, 5, 10)[1]}
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(handWins, 5, 20)}
            >
              {this.calculateMadeHandEv(handWins, 5, 20)[1]}
            </td>
          </tr>
          <tr>
            <td className="table-description">10 BB</td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(handWins, 10, 5)}
            >
              {this.calculateMadeHandEv(handWins, 10, 5)[1]}
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                handWins,
                10,
                10
              )}
            >
              {this.calculateMadeHandEv(handWins, 10, 10)[1]}
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                handWins,
                10,
                20
              )}
            >
              {this.calculateMadeHandEv(handWins, 10, 20)[1]}
            </td>
          </tr>
          <tr>
            <td className="table-description">20 BB</td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(handWins, 20, 5)}
            >
              {this.calculateMadeHandEv(handWins, 20, 5)[1]}
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                handWins,
                20,
                10
              )}
            >
              {this.calculateMadeHandEv(handWins, 20, 10)[1]}
            </td>
            <td
              className={this.getCssClassesBasedOnCalculatedEv(
                handWins,
                20,
                20
              )}
            >
              {this.calculateMadeHandEv(handWins, 20, 20)[1]}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadedRange: state.loadedRange,
    loadedFloppedCards: state.loadedFloppedCards,
    loadedDeadCards: state.loadedDeadCards,
    loadedEquities: state.loadedEquities,
  };
};

export default connect(mapStateToProps, {
  fetchEquities: fetchEquities,
})(MadeHandExpectedValueTable);
