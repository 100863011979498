export const defineCellCssClass = (cellValue, isSelected) => {
  let cssClass;

  if (isSelected) {
    cssClass = "selected-cell";
  } else {
    cssClass = "unselected-cell";
  }
  if (cellValue.indexOf("s") > -1 && cellValue.length === 3) {
    cssClass += " suited-cards";
  } else if (cellValue.indexOf("o") > -1) {
    cssClass += " off-suited-cards";
  } else if (cellValue.indexOf("h") > -1) {
    cssClass += " hearts-cards";
  } else if (cellValue.indexOf("d") > -1) {
    cssClass += " diamonds-cards";
  } else if (cellValue.indexOf("s") > -1) {
    cssClass += " spades-cards";
  } else if (cellValue.indexOf("c") > -1) {
    cssClass += " clubs-cards";
  } else {
    cssClass += " pair-cards";
  }

  return cssClass;
};
