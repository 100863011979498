import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchEquities } from "../../actions";
import { isEmpty } from "lodash";

class EquitiesTable extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.loadedRange.range !== this.props.loadedRange.range ||
      prevProps.loadedFloppedCards !== this.props.loadedFloppedCards ||
      prevProps.loadedDeadCards !== this.props.loadedDeadCards
    ) {
      if (
        this.props.loadedFloppedCards.length >= 3 &&
        this.props.loadedRange.range.length >= 1 &&
        this.props.loadedDeadCards.length === 2
      ) {
        this.props.fetchEquities(
          this.props.loadedRange.range,
          this.props.loadedFloppedCards,
          this.props.loadedDeadCards
        );
      } else {
        this.props.fetchEquities([], [], []);
      }
    }
  }

  getCssClassesBasedOnEquityPercentage = (
    equityPercentage,
    highBound = 0.8,
    lowBound = 0.5
  ) => {
    let outcome;

    if (equityPercentage >= highBound) {
      outcome = "positive";
    } else if (equityPercentage < highBound && equityPercentage >= lowBound) {
      outcome = "warning";
    } else if (equityPercentage < lowBound && equityPercentage > 0.0) {
      outcome = "negative";
    } else {
      outcome = "neutral";
    }

    if (outcome === "positive") {
      return "table-value positive-cell";
    } else if (outcome === "warning") {
      return "table-value warning-cell";
    } else if (outcome === "negative") {
      return "table-value negative-cell";
    } else {
      return "table-value";
    }
  };

  displayValueIfAvailable = (value, toFixed = null, unit = null) => {
    let displayedValue;

    if (toFixed) {
      displayedValue = value.toFixed(toFixed);
    } else {
      displayedValue = value;
    }

    if (unit) {
      return value ? `${displayedValue} ${unit}` : "";
    } else {
      return value ? `${displayedValue}` : "";
    }
  };

  render() {
    let highestFigureName = "";
    let drawHandName = "";
    let handWins = 0.0;
    let handLoses = 0.0;
    let handTies = 0.0;
    let equityNormalBet = 0.0;
    let equityAllInBet = 0.0;
    let numOuts = 0;

    if (!isEmpty(this.props.loadedEquities)) {
      highestFigureName = this.props.loadedEquities["made_hand"][
        "highest_figure_name"
      ];
      drawHandName = this.props.loadedEquities["draw_hand"]["draw_hand_name"];
      handWins = this.props.loadedEquities["made_hand"][
        "pct_hand_wins_against_range"
      ];
      handLoses = this.props.loadedEquities["made_hand"][
        "pct_hand_loses_against_range"
      ];
      handTies = this.props.loadedEquities["made_hand"][
        "pct_hand_ties_against_range"
      ];
      equityNormalBet = this.props.loadedEquities["draw_hand"][
        "pct_hand_equity_normal_bet"
      ];
      equityAllInBet = this.props.loadedEquities["draw_hand"][
        "pct_hand_equity_all_in_bet"
      ];
      numOuts = this.props.loadedEquities["draw_hand"]["num_outs"];
    }
    return (
      <table className="ui celled inverted compact table">
        <thead>
          <tr>
            <th className="table-header" colSpan={4}>Dead Cards + Flopped Cards (Board)</th>
          </tr>
          <tr>
            <th className="table-header">Made-Hand
            <span class="ui icon tooltip" data-tooltip="Made-Hand is a Hand which has already made a Figure (excl. High Card)" data-position="top center" data-variation="basic"><i class="question circle outline icon"></i></span>
            </th>
            <th className="table-header">% Equity
            <span class="ui icon tooltip" data-tooltip="Equity defines the odds to win, in a particular point in time, with the known cards." data-position="top center" data-variation="basic"><i class="question circle outline icon"></i></span>
            </th>
            <th className="table-header">Draw-Hand
            <span class="ui icon tooltip" data-tooltip="Draw-Hand is a Hand which is 1 card away from a top Figure (e.g., Straight)" data-position="top right" data-variation="basic"><i class="question circle outline icon"></i></span>
            </th>
            <th className="table-header">% Equity
            <span class="ui icon tooltip" data-tooltip="Equity defines the odds to win, in a particular point in time, with the known cards." data-position="top right" data-variation="basic"><i class="question circle outline icon"></i></span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="table-description">Hand Figure
            <span class="ui icon tooltip" data-tooltip="Name of the Poker Figure that is already made with the Cards in your Hand."  data-position="left center" data-variation="basic"><i class="question circle outline icon"></i></span>
            </td>
            <td className="table-value">
              {this.displayValueIfAvailable(highestFigureName)}
            </td>
            <td className="table-description">Draw Name
            <span class="ui icon tooltip" data-tooltip="Name of the 'almost' Figure (1 card short from a real Figure)"  data-position="left center" data-variation="basic"><i class="question circle outline icon"></i></span>
            </td>
            <td className="table-value">
              {this.displayValueIfAvailable(drawHandName)}
            </td>
          </tr>
          <tr>
            <td className="table-description">Hand
            <span class="ui icon tooltip" data-tooltip="Equity of the Cards in your Hand." data-position="left center" data-variation="basic"><i class="question circle outline icon"></i></span>
            </td>
            <td
              className={this.getCssClassesBasedOnEquityPercentage(
                handWins,
                0.8,
                0.5
              )}
            >
              {this.displayValueIfAvailable(handWins * 100, 1, "%")}
            </td>
            <td className="table-description">Normal Bet
            <span class="ui icon tooltip" data-tooltip="Equity when a normal amount of Money is used on the Bet." data-position="left center" data-variation="basic"><i class="question circle outline icon"></i></span>
            </td>
            <td
              className={this.getCssClassesBasedOnEquityPercentage(
                equityNormalBet,
                0.2,
                0.1
              )}
            >
              {this.displayValueIfAvailable(equityNormalBet * 100, 0, "%")}
            </td>
          </tr>
          <tr>
            <td className="table-description">Range
            <span class="ui icon tooltip" data-tooltip="Equity of the Cards in the Starting-Hand range." data-position="left center" data-variation="basic"><i class="question circle outline icon"></i></span>
            </td>
            <td
              className={this.getCssClassesBasedOnEquityPercentage(handLoses)}
            >
              {this.displayValueIfAvailable(handLoses * 100, 1, "%")}
            </td>
            <td className="table-description">All-In Bet
            <span class="ui icon tooltip" data-tooltip="Equity when all the Money is used on the Bet." data-position="left center" data-variation="basic"><i class="question circle outline icon"></i></span>
            </td>
            <td
              className={this.getCssClassesBasedOnEquityPercentage(
                equityAllInBet,
                0.4,
                0.2
              )}
            >
              {this.displayValueIfAvailable(equityAllInBet * 100, 0, "%")}
            </td>
          </tr>
          <tr>
            <td className="table-description">Tie
            <span class="ui icon tooltip" data-tooltip="Equity of a Tie between Hand and Range." data-position="left center" data-variation="basic"><i class="question circle outline icon"></i></span>
            </td>
            <td className={this.getCssClassesBasedOnEquityPercentage(handTies)}>
              {this.displayValueIfAvailable(handTies * 100, 1, "%")}
            </td>
            <td className="table-description">Num. outs
            <span class="ui icon tooltip" data-tooltip="Number of cards in the Deck that will make your Hand." data-position="bottom center" data-variation="basic"><i class="question circle outline icon"></i></span>
            </td>
            <td className="table-value">
              {this.displayValueIfAvailable(numOuts)}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loadedRange: state.loadedRange,
    loadedFloppedCards: state.loadedFloppedCards,
    loadedDeadCards: state.loadedDeadCards,
    loadedEquities: state.loadedEquities,
  };
};

export default connect(mapStateToProps, {
  fetchEquities: fetchEquities,
})(EquitiesTable);
