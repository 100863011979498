const reducers = (state = { id: null, name: "", range: [] }, action) => {
  switch (action.type) {
    case "LOAD_RANGE":
      return action.payload;
    case "ADD_CELL_TO_LOADED_RANGE":
      return { ...state, range: [...state.range, action.payload] };
    case "DELETE_CELL_FROM_LOADED_RANGE":
      return {
        ...state,
        range: state.range.filter((el) => el !== action.payload),
      };
    case "UPDATE_NAME_FROM_LOADED_RANGE":
      return { ...state, name: action.payload };
    case "RESET_LOADED_RANGE":
      return action.payload;
    default:
      return state;
  }
};

export default reducers;
