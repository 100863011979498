import backend from "../apis/backend";

export const fetchCombos = (range, floppedCards, deadCards) => async (
  dispatch
) => {
  const response = await backend.post(`/api/v1/combos/`, {
    range_matrix: range,
    flopped_cards: floppedCards,
    dead_cards: deadCards,
  });

  if (response.status === 200) {
    dispatch({
      type: "FETCH_COMBOS",
      payload: response.data,
    });
  } else {
    console.log("List of combos couldn't be fetched.");
  }
};

export const fetchEquities = (range, floppedCards, deadCards) => async (
  dispatch
) => {
  const response = await backend.post(`/api/v1/equities/`, {
    range_matrix: range,
    flopped_cards: floppedCards,
    dead_cards: deadCards,
  });

  if (response.status === 200) {
    dispatch({
      type: "FETCH_EQUITIES",
      payload: response.data,
    });
  } else {
    console.log("Equities couldn't be fetched.");
  }
};

export const fetchRanges = () => async (dispatch) => {
  const response = await backend.get("/api/v1/ranges/");

  if (response.status === 200) {
    dispatch({
      type: "FETCH_RANGES",
      payload: response,
    });
  } else {
    console.log("List of ranges couldn't be fetched.");
  }
};

export const deleteRange = (rangeId) => async (dispatch) => {
  const response = await backend.delete(`/api/v1/ranges/${rangeId}/`);

  if (response.status === 204) {
    dispatch({
      type: "DELETE_RANGE",
      payload: rangeId,
    });
  } else {
    console.log(`Range ${rangeId} couldn't be deleted.`);
  }
};

export const updateRange = (range) => async (dispatch) => {
  const response = await backend.put(`/api/v1/ranges/${range.id}/`, {
    name: range.name,
    range: range.range,
  });

  if (response.status === 200) {
    dispatch({
      type: "UPDATE_RANGE",
      payload: response,
    });
  } else {
    console.log(`Range ${range.id} couldn't be updated.`);
  }
};

export const createRange = (range) => async (dispatch) => {
  const response = await backend.post(`/api/v1/ranges/`, {
    name: range.name,
    range: range.range,
  });

  if (response.status === 201) {
    dispatch({
      type: "CREATE_RANGE",
      payload: response,
    });
  } else {
    console.log(`Range ${range.name} couldn't be created.`);
  }
};

export const loadRange = (range) => {
  return {
    type: "LOAD_RANGE",
    payload: range,
  };
};

export const addCellToLoadedRange = (cellValue) => {
  return {
    type: "ADD_CELL_TO_LOADED_RANGE",
    payload: cellValue,
  };
};

export const deleteCellFromLoadedRange = (cellValue) => {
  return {
    type: "DELETE_CELL_FROM_LOADED_RANGE",
    payload: cellValue,
  };
};

export const updateNameFromLoadedRange = (name) => {
  return {
    type: "UPDATE_NAME_FROM_LOADED_RANGE",
    payload: name,
  };
};

export const resetLoadedRange = () => {
  return {
    type: "RESET_LOADED_RANGE",
    payload: { id: null, name: "", range: [] },
  };
};

export const addCellToLoadedFloppedCards = (cellValue) => {
  return {
    type: "ADD_CELL_TO_LOADED_FLOPPED_CARDS",
    payload: cellValue,
  };
};

export const deleteCellFromLoadedFloppedCards = (cellValue) => {
  return {
    type: "DELETE_CELL_FROM_LOADED_FLOPPED_CARDS",
    payload: cellValue,
  };
};

export const resetLoadedFloppedCards = () => {
  return {
    type: "RESET_LOADED_FLOPPED_CARDS",
    payload: [],
  };
};

export const addCellToLoadedDeadCards = (cellValue) => {
  return {
    type: "ADD_CELL_TO_LOADED_DEAD_CARDS",
    payload: cellValue,
  };
};

export const deleteCellFromLoadedDeadCards = (cellValue) => {
  return {
    type: "DELETE_CELL_FROM_LOADED_DEAD_CARDS",
    payload: cellValue,
  };
};

export const resetLoadedDeadCards = () => {
  return {
    type: "RESET_LOADED_DEAD_CARDS",
    payload: [],
  };
};

export const updateNotification = (msgType, msgTitle, msgContent) => {
  return {
    type: "UPDATE_NOTIFICATION",
    payload: {
      msgType: msgType,
      msgTitle: msgTitle,
      msgContent: msgContent,
    },
  };
};
